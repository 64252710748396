import React, { useState } from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddressForm from "../../../shared/AddressForm/AddressForm";
import SharedCheckBox from "../../../shared/Checkbox/SharedCheckBox";
import CommentsBox from "./AdditionalCommentsBox";
import SharedBasicModal from "../../../shared/Modal/SharedBasicModal";
import {
    setConciergeAddressIsSame,
    setLoadingFlag,
    setOnTracError,
    setPickupAddress, 
    setPickUpAddressError,
    setServiceAddress,
    setVendorErrorMessage
} from "../../../Redux/ActionCreator";
import {CADILLAC_PREMIUM_CONCIERGE, DROP_OFF, PICK_UP} from "../AppointmentConstants";
import {
    getNextAvailableDateAndTime,
    getPPDLAvailability,
    setAvailabilityErrorFlag
} from "../../../shared/BackendCalls/SharedBackendGets";
import {parseAvailableResData, parseDatesResData} from "../AppointmentHelpers";
import * as appointmentConstants from "../AppointmentConstants";
import {loadGoogleMapsAPI} from "../../../shared/AddressForm/GoogleMapsApi";

const NewConciergeForm = ({ showConciergeForm, closeConciergeForm }) => {
    const {
        transportation,
        vendorError,
        onTracError,
        loadingFlag,
        conciergeAddressIsSame,
    } = useSelector((state) => state.appointmentReducer);
    const { translations, countryOptions } = useSelector((state) => state.countryOptionsReducer);
    const { dealershipSummary } = useSelector((state)=>state.prefillReducer.dealerSummaryObject)
    const {serviceAddresses,pickupAddress,dropoffAddress,conciergePhoneNumber, serviceCommunications,startDate} = useSelector((state)=>state.appointmentReducer)
    const {street,city,regionCode,postalCode,country} = useSelector((state)=>state.prefillReducer);
    const initialPickUpAddress = [street, city, regionCode, postalCode,street && country].filter(Boolean).join(",");
    const { selectedDealer } = useSelector((state) => state.locReducer);
    const dispatch = useDispatch();
    const [pickUpError, setPickUpError] = useState(false);
    const [dropOffError, setDropOffError] = useState(false);
    const [rangeError, setRangeError] = useState(false);
    const [addressObject, setAddressObject] = useState({
        pickUpAddress: "",
        dropOffAddress: "",
        isValidPickUpAddress: false,
        isValidDropOffAddress: conciergeAddressIsSame,
    });

    // Set the initial State of Pick up and drop off location.
    const getInitialState = (type)=>{
        const filteredAddress = serviceAddresses && serviceAddresses.length > 0
            ? serviceAddresses.filter((item) => item.type === type)
            : [];

        const isPickUp = type === PICK_UP;
        const payload = {
            address1: filteredAddress.length > 0 ? filteredAddress[0].addressLine1:street && isPickUp ? street:'',
            address2: filteredAddress.length > 0 ? filteredAddress[0].addressLine2:'',
            city: filteredAddress.length > 0 ? filteredAddress[0].cityName:city && isPickUp ? city:'',
            postalCode:filteredAddress.length > 0 ? filteredAddress[0].postalZone:postalCode && isPickUp ? postalCode:'',
            state: filteredAddress.length > 0 ? filteredAddress[0].countrySubentity: regionCode && isPickUp ? regionCode:'',
            country:filteredAddress.length > 0 ? filteredAddress[0].countrySubentityCode.split("-")[0]:country && isPickUp ? country:'',
            phoneNumber: type === PICK_UP && serviceCommunications?.length ? serviceCommunications[0].value : conciergePhoneNumber  && isPickUp ? conciergePhoneNumber:'',
            fullAddress: type === DROP_OFF && dropoffAddress ? dropoffAddress: pickupAddress ? pickupAddress:initialPickUpAddress || '',
            isGoogleAddress: false
        }
        return payload;
    }


    // Create Service Address Payload to pass along with appointment
    const buildServiceAddressObject = () => {
        const serviceAddress = [];

        const createAddressPayload = (type, address) => {
            const payload = {
                type,
                addressLine1: address.address1,
                cityName: address.city,
                countrySubentityCode: `${address.country}-${address.state}`,
                countrySubentity: address.state,
                postalZone: address.postalCode,
            };

            if (address.address2) {
                payload.addressLine2 = address.address2;
            }

            return payload;
        };

        const pickUpPayload = createAddressPayload('PICK_UP', addressObject.pickUpAddress);
        serviceAddress.push(pickUpPayload);

        if (!conciergeAddressIsSame && addressObject.dropOffAddress) {
            const dropOffPayload = createAddressPayload('DROP_OFF', addressObject.dropOffAddress);
            serviceAddress.push(dropOffPayload);
        }

        return serviceAddress;
    };

    // Handle Next Call
    const handleNextCall = async ()=>{
          try {
              dispatch(setLoadingFlag('true'));
              const response = await getNextAvailableDateAndTime(dealershipSummary.bac);
              if(response.status === 200 && response.data){
                  if(response.data.vendorErrorMessage?.onTracError){
                      dispatch(setOnTracError('true'));


                        //handle when api returns range error
                      if(response.data?.vendorErrorMessage?.errorMessage.includes("distance is greater than maximum miles allowed by dealer")){
                          dispatch(setVendorErrorMessage(translations["ADDRESS_OUT_OF_RANGE_ERROR"]));

                      }
                      else {
                          dispatch(setVendorErrorMessage(response.data.vendorErrorMessage.errorMessage));
                      }

                  }
                  else{
                      dispatch(setOnTracError('false'));
                       parseAvailableResData(response.data, false);
                       await handlePPDLAvailability();
                  }
              }
          }
          catch(error){
              console.log('Error occur while trying to call next call',error);
              dispatch(setAvailabilityErrorFlag('true'));
          }
          finally {
              dispatch(setLoadingFlag('false'));
          }
    }

    // Handle Premium Pick up and Delivery Availability, Only runs when next call is success
    const handlePPDLAvailability = async ()=>{
        try {
            dispatch(setLoadingFlag('true'));
            const response = await getPPDLAvailability(startDate);
            if(response.status === 200 && response.data){
                if(response.data.vendorErrorMessage?.onTracError){
                    dispatch(setOnTracError('true'));
                    dispatch(setVendorErrorMessage(response.data.vendorErrorMessage.errorMessage));

                }
                else{
                    parseDatesResData(response.data);
                    closeConciergeForm();
                }
            }
        }
        catch(error){
          console.log("Error occur while trying to get the availability =>",error);
          closeConciergeForm();
        }
        finally {
            dispatch(setLoadingFlag('false'));
        }
    }

    // Converts meters to miles if USE_MILES is true
    // Converts meters to km if USE_MILES is false
    const convertDistanceInMetersToCorrectUnits = (distanceInMeters) => {
        if (countryOptions.USE_MILES) {
            return distanceInMeters * 0.000621;
        } else {
            return distanceInMeters / 1000;
        }
    }

    // Set PP&D addresses before making availability calls
    const saveAddresses = async (pickUpAddress, dropOffAddress) => {
        setPickUpError(false);
        setDropOffError(false);
        batch(() => {
            dispatch(setServiceAddress({
                pickupAddress: pickUpAddress,
                dropoffAddress: dropOffAddress,
                serviceAddresses: buildServiceAddressObject(),
                serviceCommunications: [{
                    type: "SERVICE_PHONE",
                    "value": addressObject.pickUpAddress.phoneNumber
                }]
            }));
            dispatch(setPickUpAddressError('false'));
            dispatch(setOnTracError('false'));
        })
        await handleNextCall();
    }

    // Checks if the distance between PP&D addresses and dealer address falls within a range
    // Set PP&D addresses and make availability call if
    // 1. Dealer address is valid
    // 2. Distance from PP&D address to dealer address is less than range
    const performRangeCheck = (pickUpAddress, dropOffAddress) => {
        let allAddresses;
        if (!conciergeAddressIsSame) {
            allAddresses = [pickUpAddress, dropOffAddress];
        } else {
            allAddresses = [pickUpAddress];
        }

        loadGoogleMapsAPI().then(() => {
            const service = new window.google.maps.DistanceMatrixService(); // instantiate Distance Matrix service
            const geocoder = new window.google.maps.Geocoder();
            let dealerAddressObject = selectedDealer?.address;

            let dealer_addr = dealerAddressObject ? [dealerAddressObject.addressLine1, dealerAddressObject.cityName, dealerAddressObject.countrySubentity, dealerAddressObject.country]
                .filter(Boolean)
                .join(", ") : "";

            if (!dealer_addr && dealershipSummary?.address) {
                dealerAddressObject = dealershipSummary.address;
                dealer_addr = dealerAddressObject ? [dealerAddressObject.addressLine1, dealerAddressObject.cityName, dealerAddressObject.countrySubentity, dealerAddressObject.country]
                    .filter(Boolean)
                    .join(", ") : "";
            }

            // Geocode the address for the dealer
            geocoder.geocode({
                'address': dealer_addr
            }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {

                    // set it to the correct, formatted address if it's valid
                    dealer_addr = results.length > 0 ? results[0].formatted_address : "";
                    // fallback to latitude/longitude if no address can be found using Google Geocode
                    let dealerLocation = selectedDealer?.latitude && selectedDealer?.longitude ? selectedDealer.latitude + "," + selectedDealer.longitude : "";
                    if (dealer_addr) {
                        dealerLocation = dealer_addr;
                    }

                    if (dealerLocation) {
                        const matrixOptions = {
                            origins: [dealer_addr], // dealer location
                            destinations: allAddresses, // customer address
                            travelMode: 'DRIVING',
                            unitSystem: google.maps.UnitSystem.IMPERIAL
                        };
                        // Call Distance Matrix service
                        service.getDistanceMatrix(matrixOptions, callback);

                        // Callback function used to process Distance Matrix response
                        async function callback(response, status) {
                            if (status !== "OK") {
                                alert("Error with Google's Distance Matrix service");
                                return;
                            }
                            const pickupDistance = convertDistanceInMetersToCorrectUnits(response.rows[0].elements[0].distance.value);
                            if (pickupDistance > transportation.range) {
                                setRangeError(true);
                                setPickUpError(true);
                            } else {
                                if (response.rows[0].elements.length > 1) { // multiple Google address
                                    const dropoffDistance = convertDistanceInMetersToCorrectUnits(response.rows[0].elements[1].distance.value);
                                    if (dropoffDistance > transportation.range) { // dropoff is out of range
                                        setRangeError(true);
                                        setDropOffError(true);
                                    } else {
                                        await saveAddresses(pickUpAddress, dropOffAddress);
                                    }
                                } else { // single Google address in range
                                    await saveAddresses(pickUpAddress, dropOffAddress);
                                }
                            }
                        }
                    }
                } else {
                    // show an error if it's not
                    alert("Invalid dealer address. Can't derive latitude/longitude for dealer with Google Geocoder");
                }
            });
        });
    }

    // Save the address and fire up next and availability call
    const handleSave = async () => {
        if (
            addressObject.isValidPickUpAddress && (!conciergeAddressIsSame ? addressObject.isValidDropOffAddress:true)
        ) {

            const pickUpAddress = [addressObject.pickUpAddress.address1, addressObject.pickUpAddress.city, addressObject.pickUpAddress.state, addressObject.pickUpAddress.postalCode, addressObject.pickUpAddress.country]
                    .filter(Boolean)
                    .join(",");

            const dropOffAddress = conciergeAddressIsSame
                ? pickUpAddress
                : [addressObject.dropOffAddress.address1, addressObject.dropOffAddress.city, addressObject.dropOffAddress.state, addressObject.dropOffAddress.postalCode, addressObject.dropOffAddress.country]
                        .filter(Boolean)
                        .join(",");

            if (transportation && transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
                transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
                transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE ) {
                if (!transportation.vendorEnabled && transportation.range) {
                    performRangeCheck(pickUpAddress, dropOffAddress);
                } else {
                    await saveAddresses(pickUpAddress, dropOffAddress);
                }
            } else {
                await saveAddresses(pickUpAddress, dropOffAddress);
            }

        } else {
           if (!addressObject.isValidPickUpAddress) {
                console.log("Invalid Pick Up Error => ", addressObject.pickUpAddress);
                setPickUpError(true);
            }
            if (!addressObject.isValidDropOffAddress) {
                console.log("Invalid Drop Off Error =>", addressObject.dropOffAddress);
                setDropOffError(true);
            }
        }
    };


    const handleDropOffOnChange = ()=>{
        dispatch(setConciergeAddressIsSame(conciergeAddressIsSame ? "false" : "true"))
        setDropOffError(false);
        }

    const conciergeModalContent = (
        <div>
            <div className="appointment-concierge-header headline2">
                {transportation.code === CADILLAC_PREMIUM_CONCIERGE
                    ? (translations[CADILLAC_PREMIUM_CONCIERGE] || "CADILLAC_PREMIUM_CONCIERGE")
                    : (translations[transportation?.code] || transportation?.code )}
            </div>
            <div className="appointment-concierge-border headline-border" />
            <div className="appointment-concierge-disclaimer error-message">
                {onTracError ? vendorError : !transportation ? null : transportation.transportationCustomMessage?.customMessage
                    ? transportation.transportationCustomMessage?.customMessage
                    : translations[transportation.disclaimer]}
            </div>
            {loadingFlag ? (
                <CircularProgress />
            ) : (
                <>
                    <AddressForm
                        id="pick_up_location"
                        onChange={(data) => {
                            setAddressObject((prevState) => ({
                                ...prevState,
                                isValidPickUpAddress: data.isValidAddress,
                                pickUpAddress: data.address,
                            }));
                            if(pickUpError && data.isValidAddress){
                                setPickUpError(false)
                            }
                        }}
                        initialState={getInitialState("PICK_UP")}
                        showFormError={pickUpError}
                        showRangeError={pickUpError && rangeError}
                        showPhoneNumber={true}
                    />
                    <div className="appointment-concierge-checkbox-container">
                        <SharedCheckBox
                            checked={!conciergeAddressIsSame}
                            id="chkDropOffDifferent"
                            onChange={handleDropOffOnChange}
                        />
                        {translations.DROPOFF_DIFFERENT}
                    </div>

                    {!conciergeAddressIsSame && (
                        <div>
                            <AddressForm
                                id="drop_off_location"
                                onChange={(data) => {
                                    setAddressObject((prevState) => ({
                                        ...prevState,
                                        isValidDropOffAddress: data.isValidAddress,
                                        dropOffAddress: data.address,
                                    }));
                                    if(dropOffError && data.isValidAddress){
                                        setDropOffError(false)
                                    }
                                }}
                                initialState={getInitialState('DROP_OFF')}
                                showFormError={dropOffError}
                                showRangeError={dropOffError && rangeError}
                                showPhoneNumber={false}
                            />
                        </div>
                    )}
                    <CommentsBox />
                </>
            )}
        </div>
    );

    return (
        <>
            <SharedBasicModal
                onClose={()=>{
                    closeConciergeForm();
                    if(onTracError){
                        dispatch(setOnTracError('false'));
                        dispatch(setPickupAddress('empty'));
                        }
                    setDropOffError(false);
                    setPickUpError(false);
                    setRangeError(false);
                }}
                bodyAsText={conciergeModalContent}
                primaryButton={translations.SAVE || "SAVE"}
                handleClick={handleSave}
                addScroller={true}
                show={showConciergeForm}
            />
        </>
    );
};

export default NewConciergeForm;


