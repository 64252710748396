import React, { Component } from 'react';
import { connect } from 'react-redux';
import Script from 'react-load-script';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import MarkerClusterer from 'marker-clusterer-plus';
import scrollIntoView from 'scroll-into-view-if-needed';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import LocatorTable from './LocatorTable';
import SearchBoxToggle from './SearchBoxToggle';
import UseLocationButton from './UseLocationButton';
import AnalyticsService from '../../shared/Utils/analytics.service';
import { adobeAnalytics } from '../../shared/Utils/Utils';
import { getDealersByCords } from '../../shared/BackendCalls/SharedBackendGets';
import store from '../../Redux/Store';
import SearchBox from './SearchBox';
import MobileServiceNotAvailable from "../MobileService/MobileServiceNotAvailable";
import ShowAllLDFlag from "./ShowAllLDFlag";

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class Locator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bounds: null,
            map: null,
            minWidth: 768,
            viewFlag: false,
            selectedPlace: '',
            clusterer: null,
            currentLocMarker: null,
            oldMarkers: [],
            oldActiveMarker: null,
        };
    }

    componentDidMount = () => {
        if (AnalyticsService().loggingEnabled) {
            const analyticsService = AnalyticsService();
            analyticsService.trackPage('Dealer Locator');
            // Append the Analytics Object to the DOM
            $('body').append(adobeAnalytics('form-step'));
            console.log('Analytics Added for Page to : Locator using JQuery');
        }

        const { flowSource } = this.props;
        const { branchDealer } = this.props;

        // Only hit checkFlow when dealerships has not been initialized
        if (!this.props.dealerships.length) this.checkFlow(flowSource, branchDealer);
        window.scrollTo(0, 0);
    };

    // Calculate coordinates based on users owner center zip code, use for dealer search
    setUserCoordinates = (index) => {
        if (
            this.props.currentLatLng.lat === 38.27312 &&
            this.props.currentLatLng.lng === -98.5821872 &&
            this.props.dealerships[0]
        ) {
            const geocoder = new google.maps.Geocoder();
            if (geocoder) {
                geocoder.geocode(
                    {
                        address: this.props.dealerships[0].address.postalZone,
                    },
                    function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                                const coords = results[0].geometry.location.toJSON();
                                store.dispatch(setCoordinates(coords));
                                if (this.props.nameSearch) {
                                    for (const dealer in this.props.dealerships) {
                                        this.directionService(dealer);
                                    }
                                } else {
                                    this.directionService(index);
                                    store.dispatch(setRenderFlagTrue());
                                }
                            }
                        }
                    }
                );
            }
        } else if (index !== -1 || this.props.nameSearch) {
            if (this.props.nameSearch) {
                for (const dealer in this.props.dealerships) {
                    this.directionService(dealer);
                }
            } else {
                this.directionService(index);
            }
        }
    };

    checkFlow(flowSource, branchDealer) {
        if (flowSource === constants.OssLocatorFlow && branchDealer) {
            this.props.hideUseLocationButton(); // Hide use location button from appearing on Branch Dealer Flow
            this.props.setBranchDealers(this.props.dealerSummaryObject); // Grab branch dealers out of the prefill dealer summary object
        }
    }

    onPlacesChanged = () => {
        let places = this.autocomplete.getPlace();
        this.setState({ showErrorMessage: false });

        if (places.formatted_address) {
            if (this.props.flowSource === constants.OssLocatorFlow) {
                this.props.showLocationIconAction();
                this.props.hideUseLocationButton();
                this.props.showListAndMap();
            }
            this.props.setRenderFlagFalse(); // Reset render flag
            this.props.setSearchFlag(); // Good Search flag
            this.props.setSearchLocation(places); // Set Search (Places object) location in store
            getDealersByCords(places, false, this.props.country, this.props.bac);
            store.dispatch(setSelectedPlace(places.formatted_address));
        } else {
            //retrieve prediction list, use first result
            const displaySuggestions = (predictions, status) => {
                if (status != google.maps.places.PlacesServiceStatus.OK) {
                    return;
                }
                let geocoder = new google.maps.Geocoder();
                if (geocoder) {
                    geocoder.geocode(
                        {
                            address: predictions[0].description,
                        },
                        (results, status) => {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                                    if (results[0].formatted_address) {
                                        if (this.props.flowSource === constants.OssLocatorFlow) {
                                            this.props.showLocationIconAction();
                                            this.props.hideUseLocationButton();
                                            this.props.showListAndMap();
                                        }
                                        this.props.setRenderFlagFalse();
                                        this.props.setSearchFlag();
                                        this.props.setSearchLocation(results[0]);
                                        getDealersByCords(results[0], false, this.props.country, this.props.bac);
                                        store.dispatch(setSelectedPlace(results[0].formatted_address));
                                        this.setState({ selectedPlace: results[0].formatted_address });
                                        this.props.setSelectedPlace(results[0].formatted_address);
                                    }
                                } else {
                                    this.setState({ showErrorMessage: true });
                                }
                            }
                        }
                    );
                }
            };
            const service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions(
                { input: places.name, componentRestrictions: { country: this.props.country } },
                displaySuggestions
            );
            // highlight search bar red, show error text
        }
    };

    handleScriptLoad = () => {
        const options = {
            types: [],
            componentRestrictions: { country: this.props.country.toLowerCase() },
            fields: ['address_components', 'formatted_address', 'place_id', 'geometry', 'name'],
        };

        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('standAlone'), options);
        this.autocomplete.addListener('place_changed', this.onPlacesChanged);

        this.setState({
            bounds: new google.maps.LatLngBounds(),
            map: new google.maps.Map(document.getElementById('map'), {
                zoom: this.props.zoomValue,
                center: this.props.selectedLatLng,
            }),
        });

        if (this.props.dealerships) {
            const index = this.props.dealerships.findIndex(
                (item) =>
                    item.preferred &&
                    (item.miles === null || item.miles === this.props.vTranslations.ERROR_CALCULATING_DISTANCE)
            );

            if (!this.props.branchFlow && !this.props.hideDistance) {
                this.setUserCoordinates(index);
            }
        }
    };

    componentDidUpdate() {
        if (this.props.renderFlag && this.state.map) {
            const markers = [];
            if (this.state.clusterer) {
                this.state.clusterer.clearMarkers();
            }
            markers.splice(0, markers.length);

            let dealers = null;
            let activeMarker = null;
            if (!this.props.branchDealer) {
                dealers = this.props.dealerships;
            } else {
                dealers = this.props.sortedDealerships;
            }
            dealers.forEach((dealer, index) => {
                let marker = this.getDealerMarker(dealer, index, this.state.map);
                //set selected dealer when marker is clicked on
                marker.addListener('click', () => {
                    const selectedDealer = document.getElementById(dealer.bac);
                    scrollIntoView(selectedDealer, { behavior: 'smooth', scrollMode: 'if-needed' });
                    store.dispatch(setMarkerSelected(dealer));
                    store.dispatch(setSelectFlag());
                    this.state.map.panTo(marker.getPosition());
                });
                const position = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                if (this.state.bounds) {
                    this.state.bounds.extend(position);
                }
                markers.push(marker);
                if (dealer.isActive) {
                    if (activeMarker) {
                        activeMarker.setMap(null);
                        activeMarker = null;
                    }
                    activeMarker = this.getDealerMarker(dealer, index, this.state.map);
                }
            });

            if (!this.state.clusterer) {
                this.setState({
                    clusterer: new MarkerClusterer(this.state.map, markers, {
                        imagePath:
                            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                        minimumClusterSize: 2,
                    }),
                });
            }

            if (this.state.clusterer) {
                this.state.clusterer.addMarkers(markers);
            }

            if (this.state.oldActiveMarker !== activeMarker) {
                if (this.state.oldActiveMarker) {
                    this.state.oldActiveMarker.setMap(null);
                    this.state.oldActiveMarker = null;
                }
                this.state.oldActiveMarker = activeMarker;
                if (activeMarker) {
                    this.state.map.panTo(activeMarker.getPosition());
                }
            }

            //place pin on user location search/user's location
            if (this.props.currentLatLng && this.props.currentLatLng.lng !== 0.0 && !this.props.branchDealer) {
                if (this.state.currentLocMarker) {
                    this.state.currentLocMarker.setMap(null);
                    this.state.currentLocMarker.setPosition(this.props.currentLatLng);
                    this.state.currentLocMarker.setMap(this.state.map);
                } else {
                    this.setState({
                        currentLocMarker: new google.maps.Marker({
                            position: this.props.currentLatLng,
                            map: this.state.map,
                            icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                        }),
                    });
                }
            }

            if (this.state.map.zoom !== this.props.zoomValue) {
                this.state.map.setZoom(this.props.zoomValue);
            }

            //Update view to bounds only if new dealers have appeared, i.e. from a new search
            if (this.state.oldMarkers.length === markers.length) {
                let i;
                let difference;
                difference = false;
                for (i = 0; i < markers.length; i++) {
                    if (
                        this.state.oldMarkers[i].position.lat() !== markers[i].position.lat() ||
                        this.state.oldMarkers[i].position.lng() !== markers[i].position.lng()
                    ) {
                        difference = true;
                        break;
                    }
                }
                if (difference && this.state.bounds) {
                    this.state.map.fitBounds(this.state.bounds);
                    this.state.map.panToBounds(this.state.bounds);
                    this.state.oldMarkers = [...markers];
                }
            } else if (this.state.bounds) {
                this.state.map.fitBounds(this.state.bounds);
                this.state.map.panToBounds(this.state.bounds);
                this.state.oldMarkers = [...markers];
            }
        }
    }

    getDealerMarker(dealer, index, map) {
        const latLong = { lat: 0, lng: 0 };
        if (dealer.offsetLatitude && dealer.offsetLongitude) {
            latLong.lat = parseFloat(dealer.offsetLatitude);
            latLong.lng = parseFloat(dealer.offsetLongitude);
        } else {
            latLong.lat = parseFloat(dealer.latitude);
            latLong.lng = parseFloat(dealer.longitude);
        }
        const marker = new google.maps.Marker({
            icon: dealer.isActive ? this.highlightedIcon() : this.letteredIcon(index),
            position: latLong,
            map,
        });

        return marker;
    }

    renderTable(sortFlag, selectFlag, distanceFlag) {
        if (this.props.renderFlag) {
            this.props.branchFlow ? (sortFlag = true) : null;
            return (
                <LocatorTable
                    locations={sortFlag ? this.props.sortedDealerships : this.props.dealerships}
                    active={selectFlag}
                    distance={distanceFlag}
                />
            );
        }
        return <CircularProgress size={24} />;
    }

    renderMap = () => {
        let view;
        if (window.innerWidth <= this.state.minWidth) {
            this.state.viewFlag ? (view = true) : (view = false);
        } else {
            view = true;
        }
        let map;
        if (this.props.renderFlag) {
            map = <div className="map" id="map" />;
        } else {
            map = (
                <>
                    <div className="map" id="map" className="hide" />
                    <CircularProgress size={24} />
                </>
            );
        }
        return map;
    };

    setSort = () => {
        this.props.setSortFlag();
        setTimeout(() => {
            const el = document.getElementById(this.props.selectedDealer.bac);
            scrollIntoView(el, { behavior: 'smooth', scrollMode: 'if-needed' });
        }, 1000);
    };

    changeView = () => {
        this.setState({ viewFlag: !this.state.viewFlag });
        if (!this.state.viewFlag) {
            this.renderMap();
        }
    };

    showMore = () => {
        this.props.showMore();
    };

    setDefaultValue = () => {
        this.props.setSortFlag();
    };

    // Generates lettered icons => A,B,C
    letteredIcon = (i) => {
        if (i != null) {
            // use markers A thru Z - Logic build so there are never more than 26 dealers selected
            return `https://www.google.com/mapfiles/marker${String.fromCharCode(65 + (i % 26))}.png`;
        }
    };

    // Generates a blue (highlighted) icon when the user selects it or a dealer in the list of dealerships
    highlightedIcon = () => 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';

    directionService(index) {
        // If preferred dealership is outside of default select it will not include distance (miles) to dealership
        // Calculate distance to preferred dealer from search location using Google DirectionService
        if (index !== -1) {
            const DirectionsService = new google.maps.DirectionsService();
            DirectionsService.route(
                {
                    origin: new google.maps.LatLng(this.props.currentLatLng.lat, this.props.currentLatLng.lng),
                    destination: new google.maps.LatLng(
                        this.props.dealerships[index].latitude,
                        this.props.dealerships[index].longitude
                    ),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        const distance = result.routes[0].legs[0].distance.text;
                        store.dispatch(setDistance(distance, index, false));
                        store.dispatch(setDistanceFlag());
                    } else {
                        const distance = this.props.vTranslations.ERROR_CALCULATING_DISTANCE;
                        store.dispatch(setDistance(distance, index, true));
                        store.dispatch(setDistanceFlag());
                    }
                    if (this.props.nameSearch) {
                        store.dispatch(nameSearchCount(false));
                        if (this.props.nameSearchCount >= this.props.dealerships.length) {
                            store.dispatch(nameSearchCount(true)); // reset count
                            store.dispatch(nameSearchSort());
                        }
                    }
                }
            );
        }
    }

    render = () => (
        <div className="dl-container">
            <MobileServiceNotAvailable/>
            <Script
                url={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=${this.props.language}&region=${this.props.country}`}
                onLoad={this.handleScriptLoad}
            />

            <div className="dl-headline headline2">{this.props.vTranslations.SELECT_DEALERSHIP}</div>
            <div className="dl-headline-border headline-border" />
            <div className="dl-results-label">{this.props.vTranslations.DEALERSHIP_RES}</div>

            {this.props.branchFlow && this.props.flowSource === constants.OssLocatorFlow ? null : (
                <SearchBoxToggle
                    autocomplete={this.autocomplete}
                    showError={this.state.showErrorMessage}
                    selectedPlace={this.state.selectedPlace}
                />
            )}

            {this.props.vHideUseLocationButton || this.props.flowSource !== constants.OssLocatorFlow ? null : (
                <UseLocationButton />
            )}

            <div
                className={`main-row ${
                    this.props.branchFlow ||
                    this.props.showResultListAndMap ||
                    this.props.flowSource !== constants.OssLocatorFlow
                        ? ''
                        : 'hide'
                }`}
            >
                <div className="list-container">
                    {this.props.branchFlow && this.props.flowSource === constants.OssLocatorFlow ? null : (
                        <div className="button-container">
                            <ToggleButtonGroup
                                type="radio"
                                name="searchOption"
                                className="stat-button-link"
                                defaultValue={
                                    this.props.flowSource === constants.BrandFlow ||
                                    this.props.flowSource === constants.OssLocatorFlow
                                        ? this.setDefaultValue
                                        : 1
                                }
                                onChange={this.setSort}
                                data-dtm="select a dealer"
                            >
                                {this.props.flowSource === constants.BrandFlow ||
                                this.props.flowSource === constants.OssLocatorFlow ? null : (
                                    <ToggleButton value={1}>{this.props.vTranslations.AVAILABILITY}</ToggleButton>
                                )}
                                {this.props.flowSource === constants.BrandFlow ||
                                this.props.flowSource === constants.OssLocatorFlow ? null : (
                                    <ToggleButton value={2}>{this.props.vTranslations.DISTANCE}</ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        </div>
                    )}

                    <div className="hide-on-desktop">
                        <div className="locator-radio-group">
                            <div
                                className={`locator-radio-button radio-button ${!this.state.viewFlag ? 'checked' : ''}`}
                            >
                                <input
                                    type="radio"
                                    value="Default"
                                    checked={!this.state.viewFlag}
                                    className="default-radio-button"
                                    onChange={this.changeView}
                                />
                                <label className="radio-button-label">{this.props.vTranslations.LIST}</label>
                            </div>
                            <div
                                className={`locator-radio-button radio-button ${this.state.viewFlag ? 'checked' : ''}`}
                            >
                                <input
                                    type="radio"
                                    value="dealerSearch"
                                    checked={this.state.viewFlag}
                                    className="default-radio-button"
                                    onChange={this.changeView}
                                />
                                <label className="radio-button-label">{this.props.vTranslations.MAP}</label>
                            </div>
                        </div>
                    </div>

                    <div className={`dealer-list ${this.state.viewFlag ? 'disabled' : ''}`}>
                        {this.renderTable(this.props.sortFlag, this.props.selectFlag, this.props.distanceFlag)}

                        {this.props.renderFlag && !this.props.branchFlow && (
                            <ShowAllLDFlag>
                                {(removeShowAll) => (
                                    <div>
                                        {removeShowAll ? (<></>) :
                                            (<div>
                                                <button
                                                    className="show-more-btn primary-button"
                                                    disabled={this.props.disableShowMore}
                                                    onClick={this.showMore}
                                                >
                                                    {this.props.vTranslations.SHOW_MORE}
                                                </button>
                                            </div>)}
                                    </div>
                                )}
                            </ShowAllLDFlag>
                        )}
                    </div>
                </div>
                <div className={`map-container ${this.state.viewFlag ? '' : 'disabled'}`}>{this.renderMap()}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    bac: state.prefillReducer.bac,
    branchDealer: state.prefillReducer.branchDealer,
    branchFlow: state.locReducer.branchFlow,
    country: state.prefillReducer.country,
    currentLatLng: state.locReducer.currentLatLng,
    dealerships: state.locReducer.dealerships,
    dealerSummaryObject: state.prefillReducer.dealerSummaryObject,
    disableShowMore: state.locReducer.disableShowMore,
    distanceFlag: state.locReducer.distanceFlag,
    flowSource: state.prefillReducer.flowSource,
    hideDistance: state.locReducer.hideDistance,
    language: state.countryOptionsReducer.language,
    locale: state.prefillReducer.locale,
    markerSelected: state.locReducer.markerSelected,
    nameSearch: state.locReducer.nameSearch,
    nameSearchCount: state.locReducer.nameSearchCount,
    renderFlag: state.locReducer.renderFlag,
    searchFlag: state.locReducer.searchFlag,
    searchLocation: state.locReducer.searchLocation,
    selectedDealer: state.locReducer.selectedDealer,
    selectedLatLng: state.locReducer.selectedLatLng,
    selectFlag: state.locReducer.selectFlag,
    showResultListAndMap: state.locReducer.showListAndMap,
    sortedDealerships: state.locReducer.sortedDealerships,
    sortFlag: state.locReducer.sortFlag,
    vHideUseLocationButton: state.locReducer.hideUseLocationButton,
    vTranslations: state.countryOptionsReducer.translations,
    zoomValue: state.locReducer.zoomValue,
});

const mapDispatchToProps = (dispatch) => ({
    setSortFlag: () =>
        dispatch({
            type: 'SORT_FLAG',
        }),
    showMore: () =>
        dispatch({
            type: 'SHOW_MORE',
        }),
    setRenderFlagTrue: () =>
        dispatch({
            type: 'SET_RENDER_FLAG_TRUE',
        }),
    setBranchDealers: (dealerSummaryObject) =>
        dispatch({
            type: 'SET_BRANCH_DEALERS',
            payload: dealerSummaryObject,
        }),
    hideUseLocationButton: () =>
        dispatch({
            type: 'HIDE_USE_LOCATION_BUTTON',
        }),
    setSearchLocation: (places) =>
        dispatch({
            type: 'SET_SEARCH_LOCATION',
            payload: places,
        }),
    setSearchFlag: () =>
        dispatch({
            type: 'SET_SEARCH_FLAG',
        }),
    getDealers: (dealerships) =>
        dispatch({
            type: 'GET_DEALERS',
            payload: dealerships,
        }),
    setRenderFlagFalse: () =>
        dispatch({
            type: 'SET_RENDER_FLAG_FALSE',
        }),
    showListAndMap: () =>
        dispatch({
            type: 'SHOW_LIST_AND_MAP',
        }),
    showLocationIconAction: () =>
        dispatch({
            type: 'SHOW_LOCATION_ICON',
        }),
    setSelectedPlace: () =>
        dispatch({
            type: 'SET_SELECTED_PLACE',
        }),
});

function nameSearchSort() {
    return {
        type: 'NAME_SEARCH_SORT',
    };
}

function nameSearchCount(resetCount) {
    return {
        type: 'NAME_SEARCH_COUNT',
        payload: resetCount,
    };
}

function setRenderFlagTrue() {
    return {
        type: 'SET_RENDER_FLAG_TRUE',
    };
}

function setSelectFlag() {
    return {
        type: 'SELECT_FLAG',
    };
}

function setMarkerSelected(dealer) {
    return {
        type: 'SET_MARKER_SELECTED',
        payload: dealer,
    };
}

function setDistance(distance, index, failedDistance) {
    return {
        type: 'SET_DISTANCE',
        payload: distance,
        index,
        failedDistance,
    };
}

function setDistanceFlag() {
    return {
        type: 'SET_DISTANCE_FLAG',
    };
}

function setCoordinates(position) {
    return {
        type: 'SET_CURRENT_LAT_LNG',
        payload: position,
    };
}

function setSelectedPlace(place) {
    return {
        type: 'SET_SELECTED_PLACE',
        payload: place,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Locator);
