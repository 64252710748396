// Action Types
export const SET_ENTRY_STATE = 'SET_ENTRY_STATE';
export const SET_APP_GLOBALS = 'SET_APP_GLOBALS';
export const SET_VEHICLE_SEARCH_BY_LICENSE_RESP = 'SET_VEHICLE_SEARCH_BY_LICENSE_RESP';
export const SEARCH_BY_LICENSE_RESP_BACK_BUTTON_CLICKED = 'SEARCH_BY_LICENSE_RESP_BACK_BUTTON_CLICKED';
export const SET_SERVICE_GLOBALS = 'SET_SERVICE_GLOBALS';
export const SET_SERVICE_SELECT = 'SET_SERVICE_SELECT';
export const SET_CALL_COUNT = 'SET_CALL_COUNT';
export const SET_HEADER_FOOTER = 'SET_HEADER_FOOTER';
export const DISPLAY_OFFERS_SERVICES = 'DISPLAY_OFFERS_SERVICES';
export const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
export const CHOOSE_ONE_SERVICE_OFFER = 'CHOOSE_ONE_SERVICE_OFFER';
export const SET_SPINNER = 'SET_SPINNER';
export const SET_PREFILL_MILEAGE = 'SET_PREFILL_MILEAGE';
export const SET_OSSGLOBALEDIT = 'SET_OSSGLOBALEDIT';
export const SET_SERVICES_ERROR_FLAG = 'SET_SERVICES_ERROR_FLAG';
export const RESET_PREVIOUS_BAC = 'RESET_PREVIOUS_BAC';

export const SET_PREFILL = 'SET_PREFILL';
export const SET_APPCONFIG = 'SET_APPCONFIG';
export const SET_FLOW_SOURCE = 'SET_FLOW_SOURCE';
export const SET_PREFILL_MOCKED = 'SET_PREFILL_MOCKED';
export const SET_PREFILL_HEADER = 'SET_PREFILL_HEADER';
export const SET_MOCKED_TRANSLATIONS = 'SET_MOCKED_TRANSLATIONS';
export const SET_REWARDS_POINTS = 'SET_REWARDS_POINTS';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_CURRENT_LAT_LNG = 'SET_CURRENT_LAT_LNG';
export const GET_DEALERS = 'GET_DEALERS';
export const SET_DEALERS = 'SET_DEALERS';
export const SELECTED_DEALER = 'SELECTED_DEALER';
export const SET_OIL_CHANGE_OFFER = 'SET_OIL_CHANGE_OFFER';
export const POPULATE_SELECTED_OFFER = 'POPULATE_SELECTED_OFFER';
export const POPULATE_SELECTED_SERVICE_OFFER = 'POPULATE_SELECTED_SERVICE_OFFER';
export const RESTORE_SELECTED_SERVICE_OFFER = 'RESTORE_SELECTED_SERVICE_OFFER';
export const RESTORE_SELECTED_OFFER = 'RESTORE_SELECTED_OFFER';
export const ADD_MISC_SERVICE = 'ADD_MISC_SERVICE';
export const ADD_TIRE_FINDER_SERVICE = 'ADD_TIRE_FINDER_SERVICE';
export const ADD_TIRE_FINDER_SERVICE_STRING = 'ADD_TIRE_FINDER_SERVICE_STRING';
export const SET_MARKER_SELECTED = 'SET_MARKER_SELECTED';
export const SET_DISTANCE_FLAG = 'SET_DISTANCE_FLAG';
export const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION';
export const SET_SEARCH_FLAG = 'SET_SEARCH_FLAG';
export const SET_RENDER_FLAG_FALSE = 'SET_RENDER_FLAG_FALSE';
export const SET_RENDER_FLAG_TRUE = 'SET_RENDER_FLAG_TRUE';
export const SHOW_MORE = 'SHOW_MORE';
export const SET_BRANCH_DEALERS = 'SET_BRANCH_DEALERS';
export const CLEAR_SELECTED_DEALER = 'CLEAR_SELECTED_DEALER';
export const HIDE_DISTANCE_TRUE = 'HIDE_DISTANCE_TRUE';
export const HIDE_DISTANCE_FALSE = 'HIDE_DISTANCE_FALSE';
export const SORT_FLAG = 'SORT_FLAG';
export const SELECT_FLAG = 'SELECT_FLAG';
export const DISABLE_APPT_OBJ = 'DISABLE_APPT_OBJ';
export const SET_AVAIL_FLAG_FALSE = 'SET_AVAIL_FLAG_FALSE';
export const NAME_SEARCH_COUNT = 'NAME_SEARCH_COUNT';
export const NAME_SEARCH_SORT = 'NAME_SEARCH_SORT';
export const DISABLE_SHOW_MORE = 'DISABLE_SHOW_MORE';
export const ENABLE_SHOW_MORE = 'ENABLE_SHOW_MORE';
export const LOAD_FLAG = 'LOAD_FLAG';
export const SET_BRANCHDEALER = 'SET_BRANCHDEALER';
export const SET_PREFILLEDIT = 'SET_PREFILLEDIT';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_DEALERSUMMARY = 'SET_DEALERSUMMARY';
export const SET_ACT_COMP = 'SET_ACT_COMP';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_EXPANDED_OFFER = 'SET_EXPANDED_OFFER';
export const SET_SELECTED_PLACE = 'SET_SELECTED_PLACE';
export const SET_BRANDSITE_ORIGIN = 'SET_BRANDSITE_ORIGIN';
export const SET_MY_REWARDS_POINTS_INDICATOR = 'SET_MY_REWARDS_POINTS_INDICATOR';
export const SET_MY_GARAGE_VIN = 'SET_MY_GARAGE_VIN';
export const SET_MY_ACCOUNT_LOGIN = 'SET_MY_ACCOUNT_LOGIN';
export const SET_PROFILE_ID = 'SET_PROFILE_ID';
export const SET_EV_CERTIFIED = 'SET_EV_CERTIFIED';
export const SET_REMOVE_SHOW_ALL = 'SET_REMOVE_SHOW_ALL';
// Country Options
export const SET_COUNTRY_OPTIONS = 'SET_COUNTRY_OPTIONS';

// Appointment Page
export const SET_ADVISOR = 'SET_ADVISOR';
export const SET_ADVISOR_OPTIONS = 'SET_ADVISOR_OPTIONS';
export const SET_ADVISORS = 'SET_ADVISORS';
export const SET_APPT_DATE = 'SET_APPT_DATE';
export const SET_AVAIL_ERROR_FLG = 'SET_AVAIL_ERROR_FLG';
export const SET_BUSINESS_DNI = 'SET_BUSINESS_DNI';
export const SET_BUSINESS_NAME = 'SET_BUSINESS_NAME';
export const SET_BUSINESS_NAME_FLAG = 'SET_BUSINESS_NAME_FLAG';
export const SET_COMM_PREF = 'SET_COMM_PREF';
export const SET_CONCIERGE_ADDRESS_IS_SAME = 'SET_CONCIERGE_ADDRESS_IS_SAME';
export const SET_CONCIERGE_COMMENTS = 'SET_CONCIERGE_COMMENTS';
export const SET_CONCIERGE_PHONE_NUMBER = 'SET_CONCIERGE_PHONE_NUMBER';
export const SET_CUSTOMER_EMAIL = 'SET_CUSTOMER_EMAIL';
export const SET_CUSTOMER_FIRSTNAME = 'SET_CUSTOMER_FIRSTNAME';
export const SET_CUSTOMER_LASTNAME = 'SET_CUSTOMER_LASTNAME';
export const SET_CUSTOMER_PHONE_NUMBER = 'SET_CUSTOMER_PHONE_NUMBER';
export const SET_DATE_EDIT = 'SET_DATE_EDIT';
export const SET_DATES = 'SET_DATES';
export const SET_DNI = 'SET_DNI';
export const SET_DROPOFF_ADDRESS = 'SET_DROPOFF_ADDRESS';
export const SET_FIRST_AVAIL_DATE = 'SET_FIRST_AVAIL_DATE';
export const SET_FIRST_AVAIL_DATE_EMPTY = 'SET_FIRST_AVAIL_DATE_EMPTY';
export const SET_FROM_REVIEW_FLAG = 'SET_FROM_REVIEW_FLAG';
export const SET_HIDE_CONTACT_INFO_FLAG = 'SET_HIDE_CONTACT_INFO_FLAG';
export const SET_IS_BUSINESS_CUSTOMER = 'SET_IS_BUSINESS_CUSTOMER';
export const SET_IS_VALIDATED = 'SET_IS_VALIDATED';
export const SET_LOADING_FLAG = 'SET_LOADING_FLAG';
export const SET_MARKETING_OPT_OUT_FLAG = 'SET_MARKETING_OPT_OUT_FLAG';
export const SET_MODIFY_FLAG = 'SET_MODIFY_FLAG';
export const SET_NETWORK_ERROR_FLG = 'SET_NETWORK_ERROR_FLG';
export const SET_OPT_IN_FLAG = 'SET_OPT_IN_FLAG';
export const SET_PICKUP_ADDRESS = 'SET_PICKUP_ADDRESS';
export const SET_PREV_APPT_OBJ = 'SET_PREV_APPT_OBJ';
export const SET_PRIVACY_OPT_IN_FLAG = 'SET_PRIVACY_OPT_IN_FLAG';
export const SET_SERVICE_LANE = 'SET_SERVICE_LANE';
export const SET_SHOW_AUTH_NAME_FLAG = 'SET_SHOW_AUTH_NAME_FLAG';
export const SET_SHOW_TIMES_FLG = 'SET_SHOW_TIMES_FLG';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_TIME_INDEX = 'SET_TIME_INDEX';
export const SET_TRANSPORTATION = 'SET_TRANSPORTATION';
export const SET_TRANSPORTATION_OPTIONS = 'SET_TRANSPORTATION_OPTIONS';
export const SET_TRANSPORTATION_CALL_COMPLETE = 'SET_TRANSPORTATION_CALL_COMPLETE';
export const SET_VALID_FLAG = 'SET_VALID_FLAG';
export const SET_VENDOR_ERROR_MESSAGE = 'SET_VENDOR_ERROR_MESSAGE';
export const SET_ONTRAC_ERROR = 'SET_ONTRAC_ERROR';
export const SET_CITY = 'SET_CITY';
export const SET_POSTAL_CODE = 'SET_POSTAL_CODE';
export const SET_REGION_CODE = 'SET_REGION_CODE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_STREET = 'SET_STREET';
export const SET_GCIN = 'SET_GCIN';
export const SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER = 'SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER';
export const SET_NO_TIMESLOT_ERROR = 'SET_NO_TIMESLOT_ERROR';
export const SET_OPTION_CALL_LOADING = 'SET_OPTION_CALL_LOADING';

// Login Page
export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';
export const SHOW_LOGIN_ERROR = 'SHOW_LOGIN_ERROR';

// Review Page
export const SET_DELETE_APPOINTMENT_FLAG = 'SET_DELETE_APPOINTMENT_FLAG';
export const SET_NEW_APPOINTMENT_FLAG = 'SET_NEW_APPOINTMENT_FLAG';
export const SET_COMMENTS = 'SET_COMMENTS';
export const SET_RECORD_LOCATOR = 'SET_RECORD_LOCATOR';

// Confirmation Page
export const SET_SHOW_CANCEL_BUTTONS_FLAG = 'SET_SHOW_CANCEL_BUTTONS_FLAG';
export const SET_EDIT_FLAG_ANALYTICS = 'SET_EDIT_FLAG_ANALYTICS';
export const SET_STAR_RATING = 'SET_STAR_RATING';
export const SET_FEEDBACK_REASON = 'SET_FEEDBACK_REASON';
export const SET_CANCEL_ERROR = 'SET_CANCEL_ERROR';
export const SET_FEEDBACK_FLAG = 'SET_FEEDBACK_FLAG';

// Dealer Locator
export const RERUN_PEGA = 'RERUN_PEGA';
export const SET_IMAGE = 'SET_IMAGE';
export const SET_IMAGE_SEARCH_BY_LICENSE_IMAGE1 = 'SET_IMAGE_SEARCH_BY_LICENSE_IMAGE1';
export const SET_IMAGE_SEARCH_BY_LICENSE_IMAGE2 = 'SET_IMAGE_SEARCH_BY_LICENSE_IMAGE2';
export const SHOW_LOCATION_ICON_TRUE = 'SHOW_LOCATION_ICON_TRUE';
export const SHOW_LOCATION_ICON_FALSE = 'SHOW_LOCATION_ICON_FALSE';
export const HIDE_USE_LOCATION_BUTTON = 'HIDE_USE_LOCATION_BUTTON';
export const HIDE_USE_LOCATION_BUTTON_FALSE = 'HIDE_USE_LOCATION_BUTTON_FALSE';
export const SHOW_LIST_AND_MAP = 'SHOW_LIST_AND_MAP';
export const SHOW_LIST_AND_MAP_FALSE = 'SHOW_LIST_AND_MAP_FALSE';
export const SHOW_SEARCH_BOX_ERROR_TRUE = 'SHOW_SEARCH_BOX_ERROR_TRUE';
export const SHOW_SEARCH_BOX_ERROR_FALSE = 'SHOW_SEARCH_BOX_ERROR_FALSE';

// Header
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';

// Vehicle Page
export const SET_VEHICLE_YEARS = 'SET_VEHICLE_YEARS';
export const SET_VEHICLE_MAKES = 'SET_VEHICLE_MAKES';
export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS';
export const SET_VEHICLE_PLUS = 'SET_VEHICLE_PLUS';
export const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';

// My Rewards
export const SET_LOYALTY_DETAILS = 'SET_LOYALTY_DETAILS';
export const USE_LOYALTY_POINTS = 'USE_LOYALTY_POINTS';
export const SOFT_ENROLL = 'SOFT_ENROLL';
export const GET_LOYALTY_ERROR = 'GET_LOYALTY_ERROR';
export const ENROLL_MY_REWARDS_ERROR = 'ENROLL_MY_REWARDS_ERROR';
export const ENROLL_MY_REWARDS_FLAG = 'ENROLL_MY_REWARDS_FLAG';
export const SHOW_ENROLL_SPINNER = 'SHOW_ENROLL_SPINNER';
export const REWARDS_RAN = 'REWARDS_RAN';
export const SHOW_ERROR = 'SHOW_ERROR';
export const IS_BANNER_DISPLAYED = 'IS_BANNER_DISPLAYED';

// My Garage
export const SET_GARAGE_DATA = 'SET_GARAGE_DATA';
export const SET_GARAGE_ERROR = 'SET_GARAGE_ERROR';
export const RESET_GARAGE_ERROR = 'RESET_GARAGE_ERROR';
export const SET_GARAGE_LOADING = 'SET_GARAGE_LOADING';
export const SET_SESSION_ACTIVE = 'SET_SESSION_ACTIVE';
export const CHECK_SESSION = 'CHECK_SESSION';
export const SET_GARAGE_INDEX = 'SET_GARAGE_INDEX';
export const SET_VEHICLE_SELECTED = 'SET_VEHICLE_SELECTED';
export const SET_MY_GARAGE_ERROR = 'SET_MY_GARAGE_ERROR';
export const SET_ATTEMPTED_LOGIN = 'SET_ATTEMPTED_LOGIN';

// Analytics
export const SET_ANALYTICS_PAGE_NAME = 'SET_ANALYTICS_PAGE_NAME';

// Session
export const SET_SESSION = 'SET_SESSION';

//Mobile Service Plus
export const SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS = 'SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS';
export const SET_DEALER_ELIGIBILITY_FOR_MOBILE_PLUS = 'SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS';
export const SET_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL = 'SHOW_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL';
export const SET_MOBILE_SERVICE_NOT_AVAILABLE = 'SET_MOBILE_SERVICE_NOT_AVAILABLE';
export const SET_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER = 'SHOW_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER';
export const SET_MOBILE_SERVICE_NEXT_PAGE = 'SET_MOBILE_SERVICE_NEXT_PAGE';
export const SET_PICK_UP_ADDRESS_ERROR = 'SET_PICK_UP_ADDRESS_ERROR';
export const SET_MOBILE_SERVICE_ADDRESS_ERROR = 'SET_MOBILE_SERVICE_ADDRESS_ERROR';
export const SET_VAN_ERROR = 'SET_VAN_ERROR';
export const SET_STOP_PRESELECT_MOBILE_SERVICE = 'SET_STOP_PRESELECT_MOBILE_SERVICE';
export const SET_ON_TRAC_ERROR_ON_SUBMISSION = 'SET_ON_TRAC_ERROR_ON_SUBMISSION';
export const SET_SERVICE_ADDRESS = 'SET_SERVICE_ADDRESS';


export const SET_AVAILABILITY_GUID = 'SET_AVAILABILITY_GUID';

export const SET_NEW_SERVICE_LIST = 'SET_NEW_SERVICE_LIST';


