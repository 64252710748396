import React, {useEffect} from 'react';
import {useFlags} from "launchdarkly-react-client-sdk";
import {useDispatch} from "react-redux";

const ShowAllLDFlag = (props) => {
   const { removeShowAll }  = useFlags();
   const dispatch = useDispatch();
   useEffect(() => {
      if (removeShowAll !== undefined) {
         dispatch({ type:'SET_REMOVE_SHOW_ALL', payload: {removeShowAll} });
      }
   }, [removeShowAll, dispatch])

   return props.children(removeShowAll);
}

export default ShowAllLDFlag

