import React from 'react';
import { connect } from 'react-redux';
import { getCountryCallingCode } from 'react-phone-number-input';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import {
    setBusinessDNI,
    setBusinessName,
    setCustomerEmail,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerPhoneNumber,
    setDNI,
    setFirstName,
    setHideContactInfoFlag,
    setIsBusinessCustomer,
    setIsValidated,
    setLastName,
    setMarketingOptOutFlag,
    setOptInFlag,
    setPrivacyOptInFlag,
    setShowAuthNameFlag,
    setValidFlag,
} from '../../../Redux/ActionCreator';
import { cpfValidation, formatCNPJ, formatCPF, formatPhoneNum, validateCNPJ } from '../AppointmentHelpers';
import { getShowBusinessNameField } from '../../../shared/BackendCalls/SharedBackendGets';
import SharedTextField from '../../../shared/TextField/SharedTextField';
import { AuthenticatedFlow, AuthenticatedOCPlus } from '../../../shared/RoutingFlow/FlowComponentsConstants';

const RegexParser = require('regex-parser');
const localizationConstants = require('../../../shared/Constants/LocalizationConstants');

class Customer extends React.Component {
    constructor(props) {
        super(props);

        const isAuthFlow = this.props.flowSource === AuthenticatedFlow || this.props.flowSource === AuthenticatedOCPlus;

        if (this.props.dealerSummary && this.props.dealerSummary.deploymentMode === 'DMS') {
            getShowBusinessNameField(this.props.dealerSummary.bac);
        }

        this.props.setCustomerPhoneNumber(
            this.props.customerPhoneNumber ? formatPhoneNum(this.props.customerPhoneNumber, this.props.country) : ''
        );

        this.state = {
            businessDNI: this.props.businessDNI,
            businessName: this.props.businessName,
            customerEmail: this.props.customerEmail,
            customerFirstName: this.props.customerFirstName,
            customerLastName: this.props.customerLastName,
            customerPhoneNumber: this.props.customerPhoneNumber,
            dni: this.props.dni,
            hasBusinessDNI: true,
            hasBusinessName: true,
            hasDNI: this.props.countryOptions.SHOW_CUSTOMER_CPF !== 'true' || !isAuthFlow,
            hasEmail: isAuthFlow,
            hasFirstName: false,
            hasLastName: false,
            hasPhone: isAuthFlow,
            isAuthFlow,
            selectedOption: !this.props.isBusinessCustomer ? 'person' : 'business',
            showBusinessDNIErrorMessage: false,
            showBusinessDNIField: this.props.countryOptions.SHOW_BUSINESS_DNI === 'true',
            showBusinessDNISyntaxErrorMessage: false,
            showBusinessNameErrorMessage: false,
            showBusinessNameField: this.props.isBusinessCustomer,
            showBusinessNameSyntaxErrorMessage: false,
            showCustomerDNIField: this.props.countryOptions.SHOW_CUSTOMER_CPF === 'true' && !isAuthFlow,
            showDNIErrorMessage: false,
            showDNISyntaxErrorMessage: false,
            showEmailErrorMessage: false,
            showEmailSyntaxErrorMessage: false,
            showFirstNameErrorMessage: false,
            showFirstNameSyntaxErrorMessage: false,
            showLastNameErrorMessage: false,
            showLastNameSyntaxErrorMessage: false,
            showPhoneErrorMessage: false,
            showPhoneSyntaxErrorMessage: false,
        };
    }

    componentDidMount() {
        // Call allFieldValidation() when Appointment Page is rendered
        if (this.props.countryOptions) {
            this.allFieldValidation();
        }

        if (!this.props.customerFirstName || !this.props.customerLastName) {
            this.props.setShowAuthNameFlag('true');
        }
    }

    componentDidUpdate() {
        const hasAllFields =
            this.state.hasBusinessDNI &&
            this.state.hasBusinessName &&
            this.state.hasDNI &&
            this.state.hasEmail &&
            this.state.hasFirstName &&
            this.state.hasLastName &&
            this.state.hasPhone;

        if (this.props.countryOptions.REQUIRE_PRIVACY_CHECKBOX === 'true') {
            this.setValidFlag(this.props.privacyOptInFlag && hasAllFields);
        } else {
            this.setValidFlag(hasAllFields);
        }

        if (!this.props.customerPhoneNumber && this.props.conciergePhoneNumber) {
            this.props.setCustomerPhoneNumber(this.props.conciergePhoneNumber);
            this.setState({
                hasPhone: true,
                customerPhoneNumber: this.props.conciergePhoneNumber,
            });
        }
    }

    setValidFlag(flag) {
        if (flag) {
            this.props.setValidFlag('true');
            this.props.setIsValidated('true');
        } else {
            this.props.setValidFlag('false');
            this.props.setIsValidated('false');
        }
    }

    setValidState(hasFieldName, invalidFieldName, fieldValue, hasCorrectSyntax) {
        if (fieldValue) {
            if (hasCorrectSyntax) {
                this.setState({ [hasFieldName]: true });
            } else {
                this.setState({ [hasFieldName]: false, [invalidFieldName]: true });
            }
        } else {
            this.setState({ [hasFieldName]: false });
        }
    }

    setCustomerPhoneEmailValid() {
        const emailSyntax = RegexParser(this.props.translations.EMAIL_SYNTAX);

        this.setValidState(
            'hasEmail',
            'showEmailSyntaxErrorMessage',
            this.props.customerEmail,
            this.props.customerEmail.match(emailSyntax)
        );
        let parsedPhoneNumber;
        if (this.props.customerPhoneNumber !== '') {
            parsedPhoneNumber = parsePhoneNumber(this.props.customerPhoneNumber, this.props.country);
        }
        const flag = isValidPhoneNumber(
            formatPhoneNum(this.props.customerPhoneNumber, this.props.country),
            this.props.customerPhoneNumber !== '' && parsedPhoneNumber.country !== undefined
                ? parsedPhoneNumber.country
                : this.props.country
        );

        if (this.props.customerPhoneNumber) {
            if (flag) {
                this.setState({ hasPhone: true });
            } else {
                this.setState({ hasPhone: false, showPhoneSyntaxErrorMessage: true });
            }
        } else {
            this.setState({ hasPhone: false });
        }
    }

    // Called only when the contact field on Appointment Page has been changed:
    // From 'Person' to 'Business' or
    // From 'Business' to 'Person'
    handleContactChange = (event) => {
        if (event.target.id !== 'person') {
            this.props.setIsBusinessCustomer(true);
            this.setState({
                hasDNI: true,
                selectedOption: event.target.id,
                showBusinessNameField: true,
                showCustomerDNIField: false,
            });
            this.state.businessName
                ? this.setState({ hasBusinessName: true })
                : this.setState({ hasBusinessName: false });
            if (this.state.showBusinessDNIField) {
                this.state.businessDNI !== null
                    ? this.setState({ hasBusinessDNI: true })
                    : this.setState({ hasBusinessDNI: false });
            } else {
                this.setState({ hasBusinessDNI: true });
            }
            if (this.props.isValidated && !this.props.hideContactInfoFlag) {
                this.setState({
                    hasEmail: true,
                    hasFirstName: true,
                    hasLastName: true,
                    hasPhone: true,
                });
            }
            if (this.props.countryOptions.SHOW_BUSINESS_DNI === 'true') {
                this.setState({ showBusinessDNIField: true });

                if (!this.state.businessDNI) {
                    this.setState({ hasBusinessDNI: false });
                } else {
                    this.setState({ hasBusinessDNI: true });
                }
            } else {
                this.setState({
                    showBusinessDNIField: false,
                    hasBusinessDNI: true,
                    hasDNI: true,
                });
            }
        } else {
            this.props.setIsBusinessCustomer('false');
            this.setState({
                hasBusinessDNI: true,
                hasBusinessName: true,
                selectedOption: event.target.id,
                showBusinessDNIErrorField: false,
                showBusinessDNIField: false,
                showBusinessNameErrorMessage: false,
                showBusinessNameField: false,
            });
            this.props.setBusinessName('');

            if (this.props.isValidated && !this.props.hideContactInfoFlag) {
                this.setState({
                    hasEmail: true,
                    hasFirstName: true,
                    hasLastName: true,
                    hasPhone: true,
                });
            }

            if (this.props.countryOptions.SHOW_CUSTOMER_CPF === 'true') {
                if (this.state.dni && !this.state.showDNIErrorMessage && !this.state.showDNISyntaxErrorMessage) {
                    this.setState({ hasDNI: true });
                } else {
                    this.setState({ hasDNI: false });
                }
            } else {
                this.setState({ hasDNI: true });
            }
        }
    };

    // Validation check on input fields every time the input value changes
    handleInputChange = (event) => {
        const businessSyntax = RegexParser(this.props.countryOptions.BUSINESS_NAME_REGEX);
        const nameSyntax = RegexParser(this.props.countryOptions.NAME_REGEX);
        const noSpecialChars = RegexParser(this.props.countryOptions.NO_SPECIAL_CHARACTERS_REGEX);
        const noSpecialCharsRegex = RegexParser(this.props.countryOptions.NO_SPECIAL_CHARACTERS_BUS_REGEX);

        switch (event.currentTarget.id) {
            case 'firstName':
                this.setState({
                    customerFirstName: event.target.value,
                });

                if (!event.currentTarget.value.match(noSpecialChars) && event.currentTarget.value.match(nameSyntax)) {
                    this.setState({
                        hasFirstName: true,
                        showFirstNameErrorMessage: false,
                        showFirstNameSyntaxErrorMessage: false,
                    });
                    this.props.setCustomerFirstName(event.currentTarget.value);
                    this.props.setFirstName(event.currentTarget.value);
                } else {
                    this.setState({ hasFirstName: false });

                    if (!event.currentTarget.value.length) {
                        this.setState({
                            showFirstNameErrorMessage: true,
                            showFirstNameSyntaxErrorMessage: false,
                        });
                    } else {
                        this.setState({
                            showFirstNameSyntaxErrorMessage: true,
                            showFirstNameErrorMessage: false,
                        });
                    }
                }
                break;
            case 'lastName':
                this.setState({
                    customerLastName: event.target.value,
                });

                if (!event.currentTarget.value.match(noSpecialChars) && event.currentTarget.value.match(nameSyntax)) {
                    this.setState({
                        hasLastName: true,
                        showLastNameSyntaxErrorMessage: false,
                        showLastNameErrorMessage: false,
                    });
                    this.props.setCustomerLastName(event.currentTarget.value);
                    this.props.setLastName(event.currentTarget.value);
                } else {
                    this.setState({
                        hasLastName: false,
                    });

                    if (!event.currentTarget.value.length) {
                        this.setState({
                            showLastNameSyntaxErrorMessage: false,
                            showLastNameErrorMessage: true,
                        });
                    } else {
                        this.setState({
                            showLastNameSyntaxErrorMessage: true,
                            showLastNameErrorMessage: false,
                        });
                    }
                }
                break;
            case 'businessName':
                this.setState({
                    businessName: event.target.value,
                });

                if (
                    event.currentTarget.value.match(businessSyntax) &&
                    !event.currentTarget.value.match(noSpecialCharsRegex)
                ) {
                    this.setState({
                        hasBusinessName: true,
                        showBusinessNameErrorMessage: false,
                        showBusinessNameSyntaxErrorMessage: false,
                    });
                    this.props.setBusinessName(event.currentTarget.value);
                } else {
                    this.setState({
                        hasBusinessName: false,
                    });

                    if (!event.currentTarget.value.length) {
                        this.setState({
                            showBusinessNameErrorMessage: true,
                            showBusinessNameSyntaxErrorMessage: false,
                        });
                    } else {
                        this.setState({
                            showBusinessNameErrorMessage: false,
                            showBusinessNameSyntaxErrorMessage: true,
                        });
                    }
                }
                break;
            case 'businessDNI':
                let isMatched = false;

                this.setState({
                    businessDNI: event.target.value,
                });

                if (
                    this.props.country === localizationConstants.MX &&
                    event.currentTarget.value.length > 0 &&
                    event.target.value.match(RegexParser(this.props.countryOptions.BUSINESS_DNI_REGEX))
                ) {
                    this.setState({
                        hasBusinessDNI: true,
                        showBusinessDNIErrorMessage: false,
                        showBusinessDNISyntaxErrorMessage: false,
                    });
                    this.props.setBusinessDNI(event.target.value);
                } else {
                    if (this.props.country === localizationConstants.BR) {
                        isMatched = validateCNPJ(event.currentTarget.value);
                    } else {
                        isMatched = !!event.currentTarget.value.match(
                            RegexParser(this.props.countryOptions.BUSINESS_DNI_REGEX)
                        );
                    }

                    if (isMatched) {
                        this.setState({
                            hasBusinessDNI: true,
                            showBusinessDNIErrorMessage: false,
                            showBusinessDNISyntaxErrorMessage: false,
                        });

                        this.props.country === localizationConstants.BR
                            ? (this.state.businessDNI = formatCNPJ(event.currentTarget.value))
                            : null;

                        this.props.setBusinessDNI(formatCNPJ(event.currentTarget.value));
                    } else {
                        this.setState({
                            hasBusinessDNI: false,
                        });

                        if (!event.currentTarget.value.length) {
                            this.setState({
                                showBusinessDNIErrorMessage: true,
                                showBusinessDNISyntaxErrorMessage: false,
                            });
                        } else {
                            this.setState({
                                showBusinessDNIErrorMessage: false,
                                showBusinessDNISyntaxErrorMessage: true,
                            });
                        }
                    }
                }
                break;
            case 'email':
                const emailSyntax = RegexParser(this.props.translations.EMAIL_SYNTAX);
                this.setState({
                    customerEmail: event.target.value,
                });

                if (event.target.value.match(emailSyntax)) {
                    this.setState({
                        hasEmail: true,
                        showEmailErrorMessage: false,
                        showEmailSyntaxErrorMessage: false,
                    });
                    this.props.setCustomerEmail(event.currentTarget.value);
                } else {
                    this.setState({
                        hasEmail: false,
                    });

                    if (!event.currentTarget.value.length) {
                        this.setState({
                            showEmailErrorMessage: true,
                            showEmailSyntaxErrorMessage: false,
                        });
                    } else {
                        this.setState({
                            showEmailSyntaxErrorMessage: true,
                            showEmailErrorMessage: false,
                        });
                    }
                }
                break;
            case 'dniField':
                const DNIRegex = RegexParser(this.props.countryOptions.DNI_REGEX);
                const DNILength = parseInt(this.props.countryOptions.DNI_MAX_LENGTH, 10);

                this.setState({
                    dni: event.target.value,
                });

                if (
                    this.props.country === localizationConstants.BR &&
                    cpfValidation(event.currentTarget.value) !== false
                ) {
                    this.setState({
                        dni: formatCPF(event.currentTarget.value),
                        hasDNI: true,
                        showDNIErrorMessage: false,
                        showDNISyntaxErrorMessage: false,
                    });
                    this.props.setDNI(formatCPF(event.currentTarget.value));
                } else if (
                    this.props.country !== localizationConstants.BR &&
                    event.currentTarget.value.length <= DNILength &&
                    event.currentTarget.value.match(DNIRegex)
                ) {
                    this.setState({
                        hasDNI: true,
                        showDNIErrorMessage: false,
                        showDNISyntaxErrorMessage: false,
                    });
                    this.props.setDNI(event.currentTarget.value);
                } else {
                    if (this.state.dni && !this.state.showDNIErrorMessage && !this.state.showDNISyntaxErrorMessage) {
                        this.setState({
                            hasDNI: true,
                        });
                    } else {
                        this.setState({
                            hasDNI: false,
                        });
                    }

                    if (event.currentTarget.value.length) {
                        this.setState({
                            hasDNI: false,
                            showDNIErrorMessage: false,
                            showDNISyntaxErrorMessage: true,
                        });
                    }
                }

                if (!event.currentTarget.value.length) {
                    this.setState({
                        hasDNI: false,
                        showDNIErrorMessage: true,
                        showDNISyntaxErrorMessage: false,
                    });
                }
                break;
            default:
                console.log("Error - can't find element ID");
        }
    };

    handleMobileChange = (event) => {
        if (event.currentTarget.value.length <= 4) {
            this.setState({
                hasPhone: false,
                showPhoneErrorMessage: true,
                showPhoneSyntaxErrorMessage: false,
                customerPhoneNumber: event.currentTarget.value,
            });
        } else {
            const countryCallingCode = getCountryCallingCode(this.props.country);
            const phoneNumber = event.currentTarget.value.toString().startsWith(countryCallingCode)
                ? `+${event.currentTarget.value}`
                : `+${countryCallingCode}${event.currentTarget.value}`;
            const parsedPhoneNumber = parsePhoneNumber(event.currentTarget.value, this.props.country);

            if (
                isValidPhoneNumber(parsedPhoneNumber.nationalNumber, parsedPhoneNumber.country)
            ) {
                this.state.hasPhone = true;
                this.setState({
                    hasPhone: true,
                    showPhoneErrorMessage: false,
                    showPhoneSyntaxErrorMessage: false,
                    customerPhoneNumber: formatPhoneNum(parsedPhoneNumber.nationalNumber, this.props.country),
                });
                this.props.setCustomerPhoneNumber(formatPhoneNum(parsedPhoneNumber.nationalNumber, this.props.country));
            } else {
                this.setState({
                    hasPhone: false,
                    showPhoneErrorMessage: false,
                    showPhoneSyntaxErrorMessage: true,
                    customerPhoneNumber: event.currentTarget.value,
                });
            }
        }
    };

    handleRequiredInput = (event) => {
        if (!event.currentTarget.value.length) {
            switch (event.currentTarget.id) {
                case 'firstName':
                    this.setState({
                        showFirstNameErrorMessage: true,
                        showFirstNameSyntaxErrorMessage: false,
                    });
                    break;
                case 'lastName':
                    this.setState({
                        showLastNameErrorMessage: true,
                        showLastNameSyntaxErrorMessage: false,
                    });
                    break;
                case 'businessName':
                    this.setState({
                        showBusinessNameErrorMessage: true,
                        showBusinessNameSyntaxErrorMessage: false,
                    });
                    break;
                case 'businessDNI':
                    if (this.props.country !== localizationConstants.MX && !event.currentTarget.value.length) {
                        this.setState({
                            showBusinessDNIErrorMessage: true,
                            showBusinessDNISyntaxErrorMessage: false,
                        });
                    }
                    break;
                case 'dniField':
                    this.setState({
                        showDNIErrorMessage: true,
                        showDNISyntaxErrorMessage: false,
                    });
                    break;
                case 'phoneNumber':
                    this.setState({
                        showPhoneErrorMessage: true,
                        showPhoneSyntaxErrorMessage: false,
                    });
                    break;
                case 'email':
                    this.setState({
                        showEmailErrorMessage: true,
                        showEmailSyntaxErrorMessage: false,
                    });
                    break;
                default:
                    console.log("Error - can't find element ID");
            }
        }
    };

    /**
     *
     * @param {*} deploymentMode
     * @param {*} showBusinessName
     * @param {*} countryOption
     * @returns {boolean} showing whether business radio button should be shown
     */
    getShowBusinessName(deploymentMode, showBusinessName, countryOption) {
        const showField = !!(
            !this.state.isAuthFlow &&
            (deploymentMode !== 'DMS' || (showBusinessName && countryOption === 'true'))
        );

        return showField;
    }

    // Called when Appointment Page is initially rendered
    allFieldValidation() {
        const noSpecialChars = RegexParser(this.props.countryOptions.NO_SPECIAL_CHARACTERS_REGEX);
        const nameSyntax = RegexParser(this.props.countryOptions.NAME_REGEX);
        const businessSyntax = RegexParser(this.props.countryOptions.BUSINESS_NAME_REGEX);
        const noSpecialCharsRegex = RegexParser(this.props.countryOptions.NO_SPECIAL_CHARACTERS_BUS_REGEX);

        if (this.state.showBusinessNameField) {
            if (this.props.businessName) {
                this.setValidState(
                    'hasBusinessName',
                    'showBusinessNameSyntaxErrorMessage',
                    this.props.showBusinessName,
                    this.props.businessName.match(businessSyntax) && !this.props.businessName.match(noSpecialCharsRegex)
                );
            }
        }

        if ((this.state.showBusinessNameField && this.state.businessName) || !this.state.showBusinessNameField) {
            this.setState({ hasBusinessName: true });
        } else {
            this.setState({ hasBusinessName: false });
        }

        if (this.state.showBusinessDNIField) {
            if (this.props.businessDNI) {
                if (this.props.country === localizationConstants.BR && validateCNPJ(this.props.businessDNI)) {
                    this.setState({ hasBusinessDNI: true });
                } else if (this.props.businessDNI.match(RegexParser(this.props.countryOptions.BUSINESS_DNI_REGEX))) {
                    this.setState({ hasBusinessDNI: true });
                } else {
                    this.setState({
                        hasBusinessDNI: false,
                        showBusinessDNISyntaxErrorMessage: true,
                    });
                }
            }
        } else {
            this.setState({ hasBusinessDNI: true });
        }

        this.setValidState(
            'hasFirstName',
            'showFirstNameSyntaxErrorMessage',
            this.props.customerFirstName,
            !this.props.customerFirstName.match(noSpecialChars) && this.props.customerFirstName.match(nameSyntax)
        );

        this.setValidState(
            'hasLastName',
            'showLastNameSyntaxErrorMessage',
            this.props.customerLastName,
            !this.props.customerLastName.match(noSpecialChars) && this.props.customerLastName.match(nameSyntax)
        );

        if (this.props.countryOptions.SHOW_CUSTOMER_CPF === 'true') {
            const DNIRegex = RegexParser(this.props.countryOptions.DNI_REGEX);
            const DNILength = parseInt(this.props.countryOptions.DNI_MAX_LENGTH, 10);

            if (this.props.dni) {
                this.setValidState(
                    'hasDNI',
                    'showDNISyntaxErrorMessage',
                    this.props.dni,
                    (this.props.country === localizationConstants.BR && cpfValidation(this.props.dni) !== false) ||
                        (this.props.country !== localizationConstants.BR &&
                            this.props.dni.length <= DNILength &&
                            this.props.dni.match(DNIRegex))
                );
            } else if (this.state.dni && !this.state.showDNIErrorMessage && !this.state.showDNISyntaxErrorMessage) {
                this.setState({
                    hasDNI: true,
                });
            } else if (this.state.selectedOption === 'business') {
                this.setState({
                    hasDNI: true,
                });
            } else {
                this.setState({
                    hasDNI: false,
                });
            }
        } else {
            this.setState({
                hasDNI: true,
            });
        }

        this.setCustomerPhoneEmailValid();
        this.componentDidUpdate();
    }

    render() {
        const businessNameField = (
            <SharedTextField
                className="business-name-text-field"
                showError={this.state.showBusinessNameErrorMessage || this.state.showBusinessNameSyntaxErrorMessage}
                errorMessage={
                    this.state.showBusinessNameErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showBusinessNameSyntaxErrorMessage
                        ? this.props.translations.ENTER_VALID_BUSINESS_NAME
                        : null
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                id="businessName"
                label={this.props.translations.BUSINESS_NAME}
                pattern={this.props.countryOptions.BUSINESS_NAME_REGEX}
                required
                type="text"
                value={this.state.businessName}
            />
        );

        const businessDNIField = (
            <SharedTextField
                className="business-dni-text-field"
                showError={this.state.showBusinessDNIErrorMessage || this.state.showBusinessDNISyntaxErrorMessage}
                id="businessDNI"
                errorMessage={
                    this.state.showBusinessDNIErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showBusinessDNISyntaxErrorMessage
                        ? ` ${this.props.country}` === localizationConstants.BR
                            ? `${this.props.translations.ERROR_CPF_INVALID} Format: XX.XXX.XXX/XXXX-XX`
                            : `${this.props.translations.ERROR_CPF_INVALID} ${this.props.translations.BUSINESS_DNI_FORMAT}`
                        : null
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                label={this.props.translations.BUSINESS_DNI}
                maxLength={this.props.countryOptions.BUSINESS_DNI_MAX_LENGTH}
                type="text"
                value={this.state.businessDNI}
            />
        );

        const firstNameField = (
            <SharedTextField
                className="first-name-text-field"
                showError={this.state.showFirstNameErrorMessage || this.state.showFirstNameSyntaxErrorMessage}
                errorMessage={
                    this.state.showFirstNameErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showFirstNameSyntaxErrorMessage
                        ? this.props.translations.ENTER_VALID_FIRST_NAME
                        : null
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                id="firstName"
                label={this.props.translations.FIRST_NAME}
                pattern={this.props.countryOptions.NAME_REGEX}
                required
                type="text"
                value={this.state.customerFirstName}
            />
        );

        const lastNameField = (
            <SharedTextField
                className="last-name-text-field"
                showError={this.state.showLastNameErrorMessage || this.state.showLastNameSyntaxErrorMessage}
                errorMessage={
                    this.state.showLastNameErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showLastNameSyntaxErrorMessage
                        ? this.props.translations.ENTER_VALID_LAST_NAME
                        : null
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                id="lastName"
                label={this.props.translations.LAST_NAME}
                pattern={this.props.countryOptions.NAME_REGEX}
                required
                type="text"
                value={this.state.customerLastName}
            />
        );

        const dniField = (
            <SharedTextField
                className="dni-text-field"
                showError={this.state.showDNIErrorMessage || this.state.showDNISyntaxErrorMessage}
                errorMessage={
                    (this.state.showDNIErrorMessage ? this.props.translations.REQUIRED_FIELD_VALIDATOR : null) ||
                    (this.state.showDNISyntaxErrorMessage
                        ? `${this.props.translations.ERROR_CPF_INVALID} ${this.props.translations.CPF_FORMAT}`
                        : null)
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                id="dniField"
                label={this.props.translations.CPF}
                maxLength={this.props.countryOptions.DNI_MAX_LENGTH}
                pattern={this.props.countryOptions.DNI_REGEX}
                required
                type="text"
                value={this.state.dni}
            />
        );

        const emailField = (
            <SharedTextField
                className="email-text-field"
                showError={this.state.showEmailErrorMessage || this.state.showEmailSyntaxErrorMessage}
                errorMessage={
                    this.state.showEmailErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showEmailSyntaxErrorMessage
                        ? this.props.translations.EMAIL_VALID_VALIDATOR
                        : null
                }
                handleInputChange={this.handleInputChange}
                handleRequiredInput={this.handleRequiredInput}
                id="email"
                label={this.props.translations.EMAIL}
                maxLength={this.props.countryOptions.EMAIL_LENGTH}
                required
                type="text"
                value={this.state.customerEmail}
            />
        );

        const phoneNumberField = (
            <SharedTextField
                className="phone-number-text-field"
                showError={this.state.showPhoneErrorMessage || this.state.showPhoneSyntaxErrorMessage}
                errorMessage={
                    this.state.showPhoneErrorMessage
                        ? this.props.translations.REQUIRED_FIELD_VALIDATOR
                        : this.state.showPhoneSyntaxErrorMessage
                        ? this.props.translations.ENTER_VALID_PHONE
                        : null
                }
                handleInputChange={this.handleMobileChange}
                handleRequiredInput={this.handleRequiredInput}
                id="phoneNumber"
                label={this.props.translations.PHONE_NUMBER}
                maxLength={this.props.countryOptions.PHONE_BLURRED_LENGTH}
                required
                type="tel"
                value={this.state.customerPhoneNumber}
            />
        );

        return (
            <div className="new-contact-info-container">
                {this.getShowBusinessName(
                    this.props.dealerSummary.deploymentMode,
                    this.props.showBusinessName,
                    this.props.countryOptions.SHOW_BUSINESS_NAME_CFUI
                ) ? (
                    <div className="contact-info-radio-group">
                        <div
                            className={`new-contact-radio-button radio-button${
                                this.state.selectedOption === 'person' ? ' checked' : ''
                            }`}
                        >
                            <input
                                data-testid="person"
                                id="person"
                                type="radio"
                                value="Person"
                                checked={this.state.selectedOption === 'person'}
                                className="default-radio-button"
                                onChange={this.handleContactChange}
                            />
                            <label className="radio-button-label" htmlFor="Personal">
                                {this.props.translations.PERSONAL}
                            </label>
                        </div>
                        <div
                            className={`new-contact-radio-button radio-button${
                                this.state.selectedOption === 'business' ? ' checked' : ''
                            }`}
                        >
                            <input
                                id="business"
                                type="radio"
                                value="Business"
                                data-testid="business"
                                checked={this.state.selectedOption === 'business'}
                                className="default-radio-button"
                                onChange={this.handleContactChange}
                            />
                            <label className="new-contact-radio-label radio-button-label" htmlFor="Business">
                                {this.props.translations.BUSINESS_LABEL}
                            </label>
                        </div>
                    </div>
                ) : null}
                <div className="contact-info-field-container">
                    <div className="business-name-container">
                        {this.state.showBusinessNameField ? businessNameField : null}
                        {this.state.showBusinessDNIField && this.state.selectedOption === 'business'
                            ? businessDNIField
                            : null}
                    </div>
                    {this.props.authNameFlag ? (
                        <div className="customer-name-container">
                            {firstNameField}
                            {lastNameField}
                        </div>
                    ) : null}
                    {this.props.countryOptions.SHOW_CUSTOMER_CPF === 'true' && !this.state.showBusinessNameField
                        ? dniField
                        : null}
                    <div className="customer-contact-info-container">
                        {phoneNumberField}
                        {emailField}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authNameFlag: state.appointmentReducer.authNameFlag,
    businessDNI: state.appointmentReducer.businessDni,
    businessName: state.appointmentReducer.businessName,
    conciergePhoneNumber: state.appointmentReducer.conciergePhoneNumber,
    country: state.prefillReducer.country,
    countryOptions: state.countryOptionsReducer.countryOptions,
    customerEmail: state.appointmentReducer.customerEmail,
    customerFirstName: state.appointmentReducer.customerFirstName,
    customerLastName: state.appointmentReducer.customerLastName,
    customerPhoneNumber: state.appointmentReducer.customerPhoneNumber,
    dealershipName: state.prefillReducer.dealerSummaryObject.dealershipSummary.name,
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    dealershipURl: state.prefillReducer.dealerSummaryObject.dealershipSummary.url,
    dealerSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    displaySmsAlertOption: state.prefillReducer.dealerSummaryObject.displaySmsAlertOption,
    dni: state.appointmentReducer.dni,
    flowSource: state.prefillReducer.flowSource,
    hideContactInfoFlag: state.appointmentReducer.hideContactInfoFlag,
    isBusinessCustomer: state.appointmentReducer.isBusinessCustomer,
    isValidated: state.appointmentReducer.isValidated,
    marketingOptOutFlag: state.appointmentReducer.marketingOptOutFlag,
    optInFlag: state.appointmentReducer.optInFlag,
    privacyOptInFlag: state.appointmentReducer.privacyOptInFlag,
    showBusinessName: state.appointmentReducer.businessNameFlag,
    translations: state.countryOptionsReducer.translations,
    validFlag: state.appointmentReducer.validFlag,
});

export default connect(mapStateToProps, {
    setBusinessDNI,
    setBusinessName,
    setCustomerEmail,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerPhoneNumber,
    setDNI,
    setFirstName,
    setHideContactInfoFlag,
    setIsBusinessCustomer,
    setIsValidated,
    setLastName,
    setMarketingOptOutFlag,
    setOptInFlag,
    setPrivacyOptInFlag,
    setShowAuthNameFlag,
    setValidFlag,
})(Customer);
